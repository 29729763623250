<template>
  <div class="my-5">
    <div class="row contact">
      <div class="col-lg-6" data-aos="fade-in">
        <figure>
          <img src="@/assets/svg/phone.svg" alt="map" />
        </figure>
        <a href="tel:08502420799 " title="+90 850 242 07 99"
          >+90 850 242 07 99</a
        >
        <hr />
        <figure>
          <img src="@/assets/svg/email.svg" alt="email" />
        </figure>
        <a href="mailto:info@geccele.com" title="info@geccele.com"
          >info@geccele.com</a
        >
      </div>
      <div class="col-lg-6" data-aos="fade-in">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.0943953130577!2d30.76762121746501!3d36.91200739162637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c385886f94c0a3%3A0xa261ad728e6cd8e9!2sGeccele%20Teknoloji!5e0!3m2!1sen!2str!4v1723572752093!5m2!1sen!2str"
          width="100%"
          height="350"
          style="border: 0; border-radius: 6px"
          allowfullscreen=""
          loading="lazy"
          title="Geccele"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.inner-page {
  padding: 2% 1%;
}
.inner-page h2 {
  border-bottom: 2px solid #1f407e;
  width: max-content;
  font-size: 1.5em;
  padding-bottom: 4px;
}
.contact .col-lg-6 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.contact .col-lg-6 figure {
  border: 1px solid #1f407e;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact .col-lg-6 a {
  color: #000;
  letter-spacing: 1px;
}
</style>
