<template>
  <Breadcrumb />
  <section class="container investor-section">
    <div class="inner-page">
      <div>
        <h2 data-aos="fade-in">{{ $t("Investors") }}</h2>
        <p data-aos="fade-in">
          {{ $t("Investors1Desc") }}
        </p>
        <p data-aos="fade-in">
          {{ $t("Investors2Desc") }}
        </p>
      </div>
    </div>
  </section>
  <!-- sticky wrapper -->
  <div class="mt-5 sw-investor"></div>
  <div class="container">
    <div class="inner-page">
      <div class="row mt-5">
        <div class="col-lg-12">
          <p data-aos="fade-in">
            {{ $t("Investors3Desc") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  name: "Investors-Page",
  components: {
    Breadcrumb,
  },
};
</script>
<style scoped>
.inner-page {
  padding: 2% 1%;
}
.inner-page h2 {
  /* border-bottom: 2px solid #b24693; */
  color: #1f407e;
  width: max-content;
  font-size: 1.5em;
  padding-bottom: 0px;
}
.investor-section .left,
.investor-section .right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.investor-section .left img {
  height: 350px;
}
</style>
