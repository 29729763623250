<template>
  <Breadcrumb />
  <section class="container founder-section">
    <div class="inner-page">
      <div class="row">
        <div class="col-lg-6" data-aos="fade-right" data-aos-delay="400">
          <figure>
            <img
              class="img-fluid"
              src="../assets/fahri_ulku.jpg"
              alt="Founder"
              loading="lazy"
            />
          </figure>
        </div>
        <div class="col-lg-6 desc overflow-hidden">
          <div>
            <h2 data-aos="zoom-in" data-aos-delay="600">
              {{ $t("FahriUlku") }}
            </h2>
            <span data-aos="zoom-in" data-aos-delay="1000"
              >{{ $t("Founder") }} / CEO</span
            >
            <p data-aos="fade-left" class="mt-2" data-aos-delay="1400">
              {{ $t("FounderDesc") }}
            </p>
            <p data-aos="fade-left" class="mt-1" data-aos-delay="1800">
              {{ $t("FounderDesc2") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  name: "Career-Page",
  components: {
    Breadcrumb,
  },
};
</script>
<style scoped>
.inner-page {
  padding: 2% 1%;
}
.inner-page h2 {
  border-bottom: 2px solid #b24693;
  width: max-content;
  font-size: 1.5em;
  padding-bottom: 4px;
  text-align: left;
}
.founder-section p {
  margin-bottom: 0;
}
.founder-section a {
  color: #b24693;
  text-decoration: none;
}
.founder-section img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.founder-section .row .col-lg-6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.founder-section .desc div {
  width: 100%;
}
</style>
