import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import AboutUs from "@/views/AboutUs.vue";
import Investors from "@/views/Investors.vue";
import Career from "@/views/Career.vue";
import Contact from "@/views/Contact.vue";
import Founder from "@/views/Founder.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/investors",
    name: "Investors",
    component: Investors,
  },
  {
    path: "/career",
    name: "Career",
    component: Career,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/founder",
    name: "Founder",
    component: Founder,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
