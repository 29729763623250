<template>
  <header>
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light"
      v-if="show_web_header == true"
    >
      <div class="container">
        <router-link class="navbar-brand" to="/" title="Geccele Tech">
          <img src="@/assets/logos/geccele-logo.png" alt="logo"
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="nav">
            <li class="nav-item">
              <router-link
                to="/"
                class="nav-link"
                aria-current="page"
                :title="$t('Home')"
                >{{ $t("Home") }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="about-us" :title="$t('AboutUs')">{{
                $t("AboutUs")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="investors" :title="$t('Investors')">{{
                $t("Investors")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="career" :title="$t('Career')">{{
                $t("Career")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="founder" :title="$t('Founder')">{{
                $t("Founder")
              }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/contact" :title="$t('Contact')"
                >{{ $t("Contact") }} &#x2192;</router-link
              >
            </li>
          </ul>
        </div>
        <div class="dropdown lang-side">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ this.$i18n.locale.toUpperCase() }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <span class="dropdown-item" title="Türkçe" @click="ChangeLanguage('tr')">
                TR
              </span>
            </li>
            <li>
              <span class="dropdown-item" title="English" @click="ChangeLanguage('en')"
                >EN</span
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- mobile navbar section -->
    <nav v-if="show_mobile_header == true" id="mobile-navbar">
      <div class="container-fluid h-100">
        <div
          class="row h-100 text-center d-flex justify-content-center align-items-center"
        >
          <div class="col d-flex justify-content-center align-items-center mobile-lang">
            <div class="dropdown lang-side">
              <button
                class="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ this.$i18n.locale.toUpperCase() }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <span
                    class="dropdown-item"
                    title="Türkçe"
                    @click="ChangeLanguage('tr')"
                  >
                    TR
                  </span>
                </li>
                <li>
                  <span
                    class="dropdown-item"
                    title="English"
                    @click="ChangeLanguage('en')"
                    >EN</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            <router-link to="/" :title="$t('Home')" class="mobile-brand">
              <img
                class="mobile-logo"
                src="../assets/logos/geccele-logo.png"
                alt="logo"
              />
            </router-link>
          </div>
          <div class="col">
            <span class="mobile-toggler" @click="TOGGLE()">&#x2630;</span>
            <div class="col mobile-nav-items">
              <ul>
                <li @click="TOGGLE()">
                  <router-link
                    class="nav-item nav-link"
                    to="about-us"
                    :title="$t('AboutUs')"
                    >{{ $t("AboutUs") }}</router-link
                  >
                </li>
                <li @click="TOGGLE()">
                  <router-link
                    class="nav-item nav-link"
                    to="investors"
                    :title="$t('Investors')"
                    >{{ $t("Investors") }}</router-link
                  >
                </li>
                <li @click="TOGGLE()">
                  <router-link
                    class="nav-item nav-link"
                    to="career"
                    :title="$t('Career')"
                    >{{ $t("Career") }}</router-link
                  >
                </li>
                <li @click="TOGGLE()">
                  <router-link
                    class="nav-item nav-link"
                    to="founder"
                    :title="$t('Founder')"
                    >{{ $t("Founder") }}</router-link
                  >
                </li>
                <li @click="TOGGLE()">
                  <router-link
                    to="/contact"
                    class="nav-item nav-link"
                    :title="$t('Contact')"
                    >{{ $t("Contact") }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- Navbar End -->
  </header>
</template>

<script>
export default {
  name: "Header-Comp",
  data() {
    return {
      show_web_header: false,
      show_mobile_header: false,
    };
  },

  created() {
    window.addEventListener("resize", () => {
      this.FindScreenSize();
    });
    this.FindScreenSize();
  },

  methods: {
    FindScreenSize() {
      this.show_mobile_header = window.innerWidth < 996; // window.innerWidth < 996 bu kod boolean döndürür.
      this.show_web_header = !this.show_mobile_header; // show_web_header değişkeni sürekli diğerine bağlı olarak zıt şekilde güncellenir.
    },

    ChangeLanguage(lang) {
      if (lang == "tr") {
        this.$i18n.locale = "tr";
        // Yeni dil değerini yerel depolamada saklayın
        localStorage.setItem("lang", this.$i18n.locale);
        return;
      }
      if (lang == "ru") {
        this.$i18n.locale = "ru";
        // Yeni dil değerini yerel depolamada saklayın
        localStorage.setItem("lang", this.$i18n.locale);
        return;
      }
      this.$i18n.locale = this.$i18n.locale = "en";
      // Yeni dil değerini yerel depolamada saklayın
      localStorage.setItem("lang", this.$i18n.locale);
    },

    TOGGLE() {
      // mobile toggle controller
      let toggle = document.querySelector(".mobile-nav-items");
      if (toggle.classList.contains("show")) {
        toggle.style.display = "none";
        toggle.classList.remove("show");
      } else {
        toggle.style.display = "block";
        toggle.classList.add("show");
      }
    },
  },
};
</script>

<style scoped>
header {
  padding: 0 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
header .navbar {
  height: 80px;
  background-color: transparent !important;
}
header .nav-item .nav-link {
  color: #000;
  font-size: 18px;
}
header .nav-item:last-child {
  padding: 0px 15px;
}
header .nav-item:last-child .nav-link {
  border: 1px solid #ebebeb;
  border-radius: 20px;
}
header .nav-item .router-link-active {
  color: #1f407e;
}
.navbar-brand img {
  width: 100%;
  height: 40px;
  object-fit: cover;
}
.lang-side {
  margin: 0 15px;
  border-left: 1px solid #cbcbcb;
}
.lang-side .btn {
  border: none;
  padding: 0 0 0 25px;
}
.lang-side .dropdown-item {
  cursor: pointer;
}
.lang-side .dropdown-menu {
  min-width: max-content !important;
}

/*##### responsive design ######*/
@media (max-width: 996px) {
  header {
    padding: 0;
  }
  /* ##### mobile navbar section #####*/
  #mobile-navbar {
    position: relative;
    background-color: #fff;
    margin-top: 0;
    height: 80px;
  }
  #mobile-navbar .col {
    height: 100%;
  }
  .mobile-toggler {
    position: relative;
    top: 22px;
    font-size: 1.6em;
  }
  .mobile-nav-items {
    display: none;
    position: relative;
    margin-top: 25px;
    z-index: 9999;
  }
  .mobile-nav-items ul {
    position: absolute;
    width: 180px;
    margin-top: 30px;
    right: 0;
    background-color: #fff;
    border: 1px solid #cbcbcb;
    border-radius: 12px;
  }
  #mobile-navbar .dropdown-toggle::after {
    display: none;
  }
  #mobile-navbar #collapse-1 {
    position: absolute !important;
    top: 80px !important;
    left: 10% !important;
  }
  #mobile-navbar #collapse-2 {
    position: absolute !important;
    top: 80px !important;
    right: 2% !important;
  }
  #mobile-navbar .mobile-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  #mobile-navbar img {
    width: 120px;
    height: auto;
    object-fit: cover;
  }

  /* ##### mobile navbar section #####*/
  .lang-side {
    border: none;
  }
  #mobile-navbar {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 1px 4px;
  }
  #mobile-navbar ul {
    padding: 0;
    margin-bottom: 0;
  }
  #mobile-navbar .dropdown-toggle {
    background-color: #fff;
    border: 0px;
    color: #000 !important;
    font-size: 20px;
  }
  #mobile-navbar .dropdown-menu-right,
  .dropdown-menu {
    position: absolute;
    transform: translate3d(-119px, 41px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
    top: 20px !important;
    right: 20px !important;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #000 !important;
    text-decoration: none;
    background-color: #fff !important;
  }
  #mobile-navbar li {
    list-style: none;
  }
  #mobile-navbar li:not(:last-child) {
    border-bottom: 2px solid #000;
  }
  #mobile-navbar .nav-item,
  #mobile-navbar .dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
  }
  .mobile-lang span {
    font-size: 1.1em;
  }
}
</style>
