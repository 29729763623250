<template>
  <div class="g-direct">
    <a href="https://geccele.com" target="_blank" title="Geccele">
      <img src="@/assets/logos/geccele-com.png" alt="Geccele" />
    </a>
  </div>
</template>

<style scoped>
.g-direct {
  display: block;
  position: fixed;
  bottom: 15px;
  right: 0;
  background-color: #fff;
  padding: 6px 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  z-index: 999;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.g-direct a {
  color: #000;
  text-decoration: none;
  letter-spacing: 1px;
}

.g-direct img {
  height: 45px;
  width: auto;
  background-color: transparent;
}

@media (max-width: 996px) {
  .g-direct {
    bottom: 15px;
  }

  .g-direct img {
    height: 40px;
    width: auto;
    background-color: transparent;
  }

  .g-direct a {
    font-size: 12px;
  }
}
</style>
