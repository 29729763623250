<template>
  <Breadcrumb />
  <section class="container career-section">
    <div class="inner-page">
      <div>
        <h2 data-aos="fade-in">{{ $t("Career") }}</h2>
        <p data-aos="fade-in">
          {{ $t("CareerDesc") }}
        </p>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  name: "Career-Page",
  components: {
    Breadcrumb,
  },
};
</script>
<style scoped>
.inner-page {
  padding: 2% 1%;
}
.inner-page h2 {
  /* border-bottom: 2px solid #b24693; */
  color: #1f407e;
  width: max-content;
  font-size: 1.5em;
  padding-bottom: 0px;
}
.career-section p {
  margin-bottom: 0;
}
.career-section a {
  color: #b24693;
  text-decoration: none;
}
</style>
