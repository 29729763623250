<template>
  <footer>
    <hr />
    <div class="container d-flex justify-content-center my-4">
      <div class="row brands">
        <!-- <div class="col">
          <a
            href="https://antalyasuites.com/"
            target="_blank"
            title="Antalya Suites"
          >
            <figure>
              <img
                src="../assets/logos/antalya_suites_logo.png"
                alt="Antalya Suites"
              />
            </figure>
          </a>
        </div> -->
        <div class="col">
          <a href="https://geccele.com/" target="_blank" title="Geccele">
            <figure>
              <img src="../assets/logos/geccele-logo.png" alt="Geccele" />
            </figure>
          </a>
        </div>
      </div>
    </div>
    <div class="container-fluid text-center">
      <p class="mb-4">&copy; Geccele Tech. {{ $t("AllRightsReserved") }}</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer-Comp",
};
</script>

<style></style>
