<template>
  <Breadcrumb />
  <section class="container">
    <div class="inner-page">
      <div>
        <h2 data-aos="fade-in">{{ $t("WhoAreWe") }}</h2>
        <p data-aos="fade-in">
          {{ $t("WhoAreWeDesc") }}
        </p>
      </div>
      <div class="mt-5">
        <h2 data-aos="fade-in">{{ $t("MissionAndVision") }}</h2>
        <p data-aos="fade-in">
          {{ $t("MissionAndVision1Desc") }}
        </p>
        <p class="mt-2" data-aos="fade-in">
          {{ $t("MissionAndVision2Desc") }}
        </p>
        <p class="mt-2" data-aos="fade-in">
          {{ $t("MissionAndVision3Desc") }}
        </p>
      </div>
      <div class="mt-5">
        <h2 data-aos="fade-in">{{ $t("HistoryAndOriginsOfOurCompany") }}</h2>
        <p data-aos="fade-in">
          {{ $t("HistoryAndOriginsOfOurCompany1Desc") }}
        </p>
        <p class="mt-2" data-aos="fade-in">
          {{ $t("HistoryAndOriginsOfOurCompany2Desc") }}
        </p>
        <p class="mt-2" data-aos="fade-in">
          {{ $t("HistoryAndOriginsOfOurCompany3Desc") }}
        </p>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  name: "AboutUs-Page",
  components: {
    Breadcrumb,
  },
};
</script>
<style scoped>
.inner-page {
  padding: 2% 1%;
}
.inner-page h2 {
  /* border-bottom: 2px solid #1f407e; */
  width: max-content;
  color: #1f407e;
  font-size: 1.5em;
  padding-bottom: 0px;
}
</style>
