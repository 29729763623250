import { createApp } from "vue";
import { createI18n } from 'vue-i18n'
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
//import store from "./store";
// Initalize bootstrap module
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
// importing AOS css style globally
import 'aos/dist/aos.css';
// lang bileşeni
const i18n = new createI18n({
    locale: 'en', // default
    fallbackLocale: 'en', // undefined lang to default
    messages: {
        en: require('@/i18n/en.json'), // en 
        tr: require('@/i18n/tr.json') // tr  
    }
});
createApp(App).use(i18n).use(router).mount("#app");
