<template>
  <Header />
  <router-view />
  <Footer />
  <!-- <BackToTop /> -->
  <GecceleDirect />
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
// import BackToTop from "./components/BackToTop.vue";
import GecceleDirect from "./components/GecceleDirect.vue";
import AOS from "aos";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    // BackToTop,
    GecceleDirect,
  },
  mounted() {
    AOS.init();
  },
  created() {
    // Sayfa yüklendiğinde dil değerini yerel depolamadan kontrol edin ve ayarlayın
    const savedLocale = localStorage.getItem("lang");
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
  },
};
</script>
