<template>
  <section class="breadcrumb">
    <div class="container-fluid">
      <div class="d-flex justify-content-center align-items-center">
        <span
          ><router-link to="/" :title="$t('Home')">{{
            $t("Home")
          }}</router-link></span
        >
        <span>&nbsp;/&nbsp;</span>
        <span>{{ $t(pageTitle) }}</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BreadcrumbComp",
  data() {
    return {
      pageTitle: "",
    };
  },
  mounted() {
    this.setTitleBasedOnPath();
  },
  methods: {
    setTitleBasedOnPath() {
      const loc = window.location.pathname;
      let title = "";
      if (loc === "/about-us") {
        title = "AboutUs";
      }
      if (loc === "/investors") {
        title = "Investors";
      }
      if (loc === "/career") {
        title = "Career";
      }
      if (loc === "/contact") {
        title = "Contact";
      }
      if (loc === "/founder") {
        title = "Founder";
      }
      this.pageTitle = title;
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  width: 100%;
  height: 60px;
  background-color: #1f407e;
  /* background-color: #b24693; */
  color: #fff;
}
.breadcrumb .container-fluid .d-flex {
  height: 60px;
}
.breadcrumb span {
  font-size: 18px;
}
.breadcrumb a {
  color: #fff;
}
</style>
