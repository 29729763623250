<template>
  <Breadcrumb />
  <section class="container">
    <Contact />
  </section>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Contact from "@/components/Contact-Inner.vue";
export default {
  name: "Contact-Page",
  components: {
    Breadcrumb,
    Contact,
  },
};
</script>
